<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-if="!isLoading">
      <div v-show="!isLoading" class="m-2 m-sm-3 p-3 pre-box title-panel">
        <b-row>
          <b-col sm="3 border-right">
            <label>Total Visit Campaign Page</label>
            <div>
              <span class="text-score">
                {{ form.visit_campaign | numeral("0,0") }}</span
              >
            </div>
          </b-col>
          <b-col sm="3 border-right">
            <label>User Acquisition Cost</label>
            <div>
              <span class="text-score"> {{ form.cost | numeral("0,0") }}</span>
            </div>
          </b-col>
          <b-col sm="3 border-right">
            <label>Total Customer</label>
            <div>
              <span class="text-score">
                {{ form.total_customer | numeral("0,0") }}

                <div class="badge badge-score">
                  ({{ form.total_new_customer }} New User)
                </div>
              </span>
            </div>
          </b-col>
          <b-col sm="3">
            <label>Total Spending / Cost (ROI)</label>
            <div>
              <span class="text-score"
                >{{
                  !isFinite(form.total_spending / form.budget)
                    ? 0
                    : (form.total_spending / form.budget) | numeral("0,0")
                }}
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row class="px-2 px-sm-3">
        <b-col md="5">
          <b-input-group>
            <b-form-input
              type="text"
              id="header-search-bar"
              class="search-bar"
              placeholder="Search Member ID, Name-Surname"
              v-model="filter.search"
              v-debounce:200ms="submitFilter"
              @keyup.enter="submitFilter"
            >
            </b-form-input>
            <b-input-group-append is-text class="mr-1">
              <b-iconstack font-scale="2" @click.prevent="submitFilter">
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-iconstack>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="7" class="text-right">
          <b-button
            v-b-toggle.sidebar-filter
            class="btn button btn-mobile ml-1"
            id="filterBtn"
          >
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="main-color mr-0 mr-sm-1 pointer"
            />
            <span class="d-none d-sm-inline">Filter </span>
          </b-button>
          <button
            type="button"
            class="btn button btn-mobile ml-2"
            @click.prevent="openModal"
          >
            <font-awesome-icon
              icon="file-export"
              class="text-black d-sm-none"
            />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="file-export" class="pointer" />
              Export
            </span>
          </button>
        </b-col>
      </b-row>
      <b-sidebar
        id="sidebar-filter"
        v-model="sidebarShow"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
        aria-labelledby="sidebar-filter-title"
        no-header
        width="80vw"
      >
        <template>
          <div class="px-3">
            <b-row class="header-filter">
              <b-col class="text-filter"> Filter </b-col>
              <b-col class="text-right">
                <button class="btn-clear" @click="clearFilter">
                  x <span class="text-under">Clear Filter</span>
                </button>
              </b-col>
            </b-row>
            <div>
              <div class="my-2">
                <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
                <b-form-input
                  type="text"
                  placeholder="Search Member ID , Name-Surname"
                  class="input-container search-filter"
                  v-model="filter.search"
                  @keypress.enter="submitFilter"
                  v-debounce:200ms="submitFilter"
                ></b-form-input>
              </div>
              <div class="">
                <div class="mt-2">
                  <div class="font-weight-bold mb-2">Date Filter</div>
                  <div class="input-container">
                    <datetime
                      id="date"
                      :input-style="styleDatetime"
                      v-model="filter.date"
                      placeholder="DD/MM/YYYY"
                      format="dd/MM/yyyy"
                      value-zone="Asia/Bangkok"
                      ref="filterDate"
                    >
                    </datetime>
                    <div
                      id="startDateIcon"
                      class="icon-primary text-right"
                      @click="$refs.filterDate.isOpen = true"
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        class="pointer color-primary"
                        color="var(--primary-color)"
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <b-form-group>
                    <template #label>
                      <div class="font-weight-bold">Consent</div>
                      <b-form-checkbox
                        v-model="consentAll"
                        aria-describedby="status"
                        aria-controls="status"
                        @change="handleAllConsent"
                      >
                        All
                      </b-form-checkbox>
                    </template>
                    <template v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                        id="consent"
                        v-model="filter.consent_list"
                        :options="censent_option"
                        :aria-describedby="ariaDescribedby"
                        name="consent"
                        class="ml-4"
                        value-field="id"
                        text-field="name"
                        aria-label="Individual consent"
                        stacked
                      ></b-form-checkbox-group>
                    </template>
                  </b-form-group>
                </div>
                <div class="mt-2">
                  <b-form-group>
                    <template #label>
                      <div class="font-weight-bold">Status</div>
                      <b-form-checkbox
                        v-model="statusAll"
                        aria-describedby="status"
                        aria-controls="status"
                        @change="handleAllStatus"
                      >
                        All
                      </b-form-checkbox>
                    </template>
                    <template v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                        id="status"
                        v-model="filter.status_list"
                        :options="status_option"
                        :aria-describedby="ariaDescribedby"
                        name="status"
                        class="ml-4"
                        value-field="id"
                        text-field="name"
                        aria-label="Individual status"
                        stacked
                      ></b-form-checkbox-group>
                    </template>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div
            class="d-flex justify-content-between shadow align-items-center filter-btn"
          >
            <div class="w-100">
              <b-button
                block
                variant="dark"
                class="text-light btn-block"
                @click.prevent="hide"
              >
                Cancel
              </b-button>
            </div>
            <div class="w-100">
              <b-button
                @click.prevent="submitFilter"
                block
                variant="light"
                id="handle-search-filter"
                class="text-body btn-block btn-main"
              >
                Search
              </b-button>
            </div>
          </div>
        </template>
      </b-sidebar>
      <b-row class="my-3 px-sm-3">
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="displayItems"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(customerDetail)="data">
              <div class="pl-2 text-left">
                <router-link
                  :to="`/report/customer/${data.item.user_guid}`"
                  class="text-purple-1"
                >
                  <u>{{ data.item.name }} {{ data.item.lastname }}</u>
                </router-link>

                <p class="mb-0 text-gray">{{ data.item.phone }}</p>
                <p class="mb-0 text-gray">{{ data.item.email }}</p>
              </div>
            </template>
            <template v-slot:cell(customField1)="data">
              <div>
                <a
                  v-if="data.item.customField1Type == 8"
                  :href="data.item.customField1"
                  target="_blank"
                >
                  <div
                    class="table-image"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.customField1 + ')',
                    }"
                  ></div>
                </a>
                <div v-else>{{ data.item.customField1 }}</div>
              </div>
            </template>
            <template v-slot:cell(customField2)="data">
              <div>
                <a
                  v-if="data.item.customField2Type == 8"
                  :href="data.item.customField2"
                  target="_blank"
                >
                  <div
                    class="table-image"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.customField2 + ')',
                    }"
                  ></div>
                </a>
                <div v-else>{{ data.item.customField2 }}</div>
              </div>
            </template>
            <template v-slot:cell(customField3)="data">
              <div>
                <a
                  v-if="data.item.customField3Type == 8"
                  :href="data.item.customField3"
                  target="_blank"
                >
                  <div
                    class="table-image"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.customField3 + ')',
                    }"
                  ></div>
                </a>
                <div v-else>{{ data.item.customField3 }}</div>
              </div>
            </template>
            <template v-slot:cell(created_time)="data">
              <div class="d-flex justify-content-center">
                {{
                  $moment(data.item.created_time).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                }}
              </div>
            </template>
            <template v-slot:cell(is_consent)="data">
              <div>
                <span
                  :class="
                    data.item.is_consent == 2
                      ? 'text-purple'
                      : data.item.is_consent == 1
                      ? 'text-success'
                      : 'text-danger'
                  "
                  >{{
                    data.item.is_consent == 2
                      ? "waiting for consent"
                      : data.item.is_consent == 1
                      ? "Approve"
                      : "Disapprove"
                  }}</span
                >
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <div>
                <span
                  :class="
                    data.item.status == 2
                      ? 'text-danger'
                      : data.item.status == 1
                      ? 'text-success'
                      : 'text-purple'
                  "
                  >{{
                    data.item.status == 2
                      ? "Reject"
                      : data.item.status == 1
                      ? "Approve"
                      : "Pending"
                  }}</span
                >
              </div>
            </template>
            <template v-slot:cell(manage)="data">
              <div>
                <span
                  class="mb-0 manage-btn"
                  @click="editCanpaign(data.item.id)"
                  >Detail</span
                ><br />
                <span
                  :class="['mb-0 manage-btn', { error: data.item.status != 0 }]"
                  @click="openMessageModal(data.item)"
                  >Reject</span
                ><br />
                <span
                  :class="['mb-0 manage-btn', { error: data.item.status != 0 }]"
                  @click="updateCanpaign(data.item, 2)"
                  >Approve</span
                >
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        class="px-3 pb-3"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        :hideTake="true"
        @pagination="pagination"
      />
    </div>
    <b-modal
      v-model="modalMessageShow"
      title="Product List"
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      header-class="bg-purple1"
    >
      <template #modal-header>
        <div class="w-100">
          <strong class="title-modal white-color">Submit/Reject</strong>
          <b-icon
            icon="x-circle-fill"
            class="float-right pointer white-color"
            @click="modalMessageShow = false"
          ></b-icon>
        </div>
      </template>
      <div>
        <label>Additional Reasons (if any)</label>
        <InputTextArea
          textFloat=""
          type="text"
          v-model="message"
          class="mt-0 custom-input"
          placeholder="Provide Additional Information"
          rows="3"
          name="message"
        />
        <b-row>
          <b-col sm="6">
            <b-button class="cancel-btn" @click="cancelMessageModel">
              Cancel
            </b-button>
          </b-col>
          <b-col sm="6" class="d-flex justify-content-end">
            <b-button class="approve-btn" @click="submitMessageModel">
              Submit
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="formExport"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (formExport.email = val)"
      @submit="exportExcel"
    />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import OtherLoading from "@/components/loading/OtherLoading";
import InputTextArea from "@/components/inputs/InputTextArea";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  components: {
    OtherLoading,
    InputTextArea,
    ModalAlertError,
    ModalInputEmail,
  },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    filter: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: Number,
    },
    form: {
      required: false,
    },
    userFill: {
      required: false,
    },
  },
  data() {
    return {
      consentAll: true,
      statusAll: true,
      sidebarShow: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      modalMessage: "",
      modalMessageShow: false,
      detailSelect: {},
      message: "",
      isLoading: false,
      censent_option: [
        {
          id: 2,
          name: "Pending",
        },
        {
          id: 1,
          name: "Approve",
        },
        {
          id: 0,
          name: "Reject",
        },
        {
          id: 6,
          name: "Recall",
        },
        {
          id: 7,
          name: "Suspend",
        },
      ],
      status_option: [
        { id: 0, name: "Pending" },
        { id: 1, name: "Approve" },
        { id: 2, name: "Reject" },
      ],
      formExport: {
        email: "",
      },
    };
  },
  watch: {
    "filter.consent_list"(newValue) {
      if (newValue.length === 0) {
        this.consentAll = false;
      } else if (newValue.length === this.censent_option.length) {
        this.consentAll = true;
      } else {
        this.consentAll = false;
      }
    },
    "filter.status_list"(newValue) {
      if (newValue.length === 0) {
        this.statusAll = false;
      } else if (newValue.length === this.status_option.length) {
        this.statusAll = true;
      } else {
        this.statusAll = false;
      }
    },
  },
  computed: {
    displayItems() {
      for (const item of this.items) {
        for (const fieldName in item) {
          const field = item[fieldName];
          if (!field) continue;
          if (Array.isArray(field)) {
            let dataToString = "";
            for (const item of field) {
              dataToString = dataToString + ", " + item;
            }
            field = dataToString.slice(2);
          }
          item[fieldName] = field;
        }
      }
      return this.items;
    },
  },
  methods: {
    clearExportFilter() {
      this.formExport.email = "";
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.$emit("getListUser", this.filter);
    },
    async exportExcel() {
      this.$EventBus.$emit("showLoading");
      let payload = {
        id: this.id,
        email: this.formExport.email,
      };
      await this.$store.dispatch("exportCampaignForm", payload);
      this.successAlert(
        "The file has been sent to email. (If not received, please wait 5-10 minutes.)"
      );
      this.$EventBus.$emit("hideLoading");
    },
    openModal() {
      this.$refs.ModalInputEmail.show();
    },
    editCanpaign(id) {
      this.$router.push({
        path: `/campaign/detail/${id}?campaignID=${this.id}&formID=${this.$route.params.id}`,
      });
    },
    async updateCanpaign(detail, status) {
      try {
        if (detail.status == 0) {
          const { user_fill } = this.userFill.find((el) => el.id == detail.id);
          let body = {
            is_accept: status,
            user_id: detail.id,
            user_guid: detail.user_guid,
            point: 0,
            note: "",
            telephone: detail.phone,
            campaign_id: this.form.campaign_id,
            message: this.message,
            user_fill: user_fill ? user_fill : [],
          };

          await this.$store.dispatch("updateCampaign", body);
          let data = this.$store.state.form.respUpdateCampaign;

          if (data.result == 1) {
            this.successAlert().then(
              function () {
                this.clearFilter();
                this.$emit("getUserRegisterForm");
              }.bind(this)
            );
          } else {
            this.errorAlert(data.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleAllConsent(value) {
      if (value) {
        this.filter.consent_list = [2, 1, 0, 6, 7];
      } else {
        this.filter.consent_list = [];
      }
    },
    handleAllStatus(value) {
      if (value) {
        this.filter.status_list = [0, 1, 2];
      } else {
        this.filter.status_list = [];
      }
    },
    clearFilter() {
      this.filter = {
        page: 1,
        take: 10,
        search: "",
        date: "",
        consent_list: [2, 1, 0, 6, 7],
        status_list: [0, 1, 2],
      };
    },
    hide() {
      this.sidebarShow = false;
    },
    submitFilter() {
      this.filter.page = 1;
      this.hide();
      this.filter.take = 10;
      this.$emit("getListUser", this.filter);
    },
    openMessageModal(detail) {
      if (detail.status == 0) {
        this.detailSelect = detail;
        this.submitMessageModel();
        // this.modalMessageShow = true;
      }
    },
    cancelMessageModel() {
      this.message = "";
      this.detailSelect = {};
      this.modalMessageShow = false;
    },
    submitMessageModel() {
      this.modalMessageShow = false;
      this.updateCanpaign(this.detailSelect, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
.text-filter {
  font-weight: 600;
}

::v-deep .btn-main,
.btn-main button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.filter-btn .btn {
  border-radius: 0 !important;
}

.text-gray {
  color: #707070;
}
.text-purple {
  color: var(--primary-color);
}
.manage-btn {
  cursor: pointer;
  color: #4f5d73;
}
.manage-btn:hover {
  cursor: pointer;
  color: var(--primary-color);
  text-decoration: underline;
}
.manage-btn.error {
  cursor: context-menu;
  color: #a1a1a1;
}
.manage-btn.error:hover {
  cursor: context-menu;
  color: #a1a1a1;
  text-decoration: none;
}
.approve-btn {
  color: #fff;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.approve-btn:hover {
  color: var(--primary-color);
  background-color: #fff;
}
.cancel-btn {
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}
.cancel-btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}
.text-score {
  color: var(--primary-color);
  font-size: 38px;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 0rem;
}
.badge-score {
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  font-size: 30%;
}
</style>
